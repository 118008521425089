import { useState } from "react";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { Images } from "../../utils";
import "./index.scss";

const detail1 = [
  {
    title: "Truy cập toàn bộ hơn 100.000 thẻ từ chuẩn sắc thái",
    isFree: true,
  },
  {
    title: "Đọc truyện chêm Việt-Anh được cập nhật hằng ngày",
    isFree: true,
  },
  {
    title: "Lướt hàng chục ngàn video ngắn để hiểu ngữ cảnh từ vựng",
    isFree: true,
  },
  {
    title: "Lưu thẻ từ vựng muốn học vào kho riêng cá nhân hóa",
    isFree: false,
  },
];

const detail2 = [
  // {
  //   title: "Tham gia hơn 100 buổi kiến thức live hằng tháng",
  //   isFree: false,
  // },
  {
    title: "Luyện tập nói trong nhóm cùng người bản xứ",
    isFree: false,
  },
  {
    title: "Tìm bạn tập nói tiếng Anh trong cộng đồng",
    isFree: true,
  },
];

const detail3 = [
  {
    title: "Truy cập tính năng luyện tập thẻ từ vựng",
    isFree: false,
  },
  {
    title:
      "Nhắc nhở ôn thẻ từ vựng đến hạn ôn hoặc bạn đã quên",
    isFree: false,
  },
  {
    title: "Dùng tính năng chat với AI để hỏi đáp 24/7",
    isFree: true
  },
  {
    title: "Làm bài tự luyện nghe bằng hàng chục ngàn video có sẵn hoặc tự chọn",
    isFree: false
  },
  {
    title: "Chỉnh phát âm với hàng chục ngàn câu hỏi và được nhận xét từng âm",
    isFree: false
  },
  {
    title: "Luyện đọc tự chọn từ nguồn bạn nhập vào",
    isFree: false
  },
  {
    title: "Hỗ trợ trích xuất từ vựng để tăng khả năng viết",
    isFree: false
  }
];

export default function Subscription() {
  const [isMonth, setIsMonth] = useState(true);

  return (
    <div className="subscription">
      <Fade bottom delay={300}>
        <span className="title">Chọn gói bạn muốn</span>
      </Fade>

      <div className="action-wrap">
        <Fade bottom delay={300}>
          <div className="select-wrap">
            <div className="package-select-wrap">
              <div
                className={`package-select ${isMonth ? "active" : ""}`}
                onClick={() => setIsMonth(true)}
              >
                <span>Theo Tháng</span>
              </div>
              {/* <div
                className={`package-select ${!isMonth ? "active" : ""}`}
                onClick={() => setIsMonth(false)}
              >
                <span>Theo Năm</span>
              </div> */}
            </div>
          </div>
        </Fade>

        <Bounce>
          <div className="money">
            <span>{isMonth ? "499k" : "4.199k"} VNĐ</span>
            <div className="chip">Subscribe</div>
          </div>
        </Bounce>

        <Bounce>
          <div className="money disabled">
            <span>FREE</span>
            <div className="chip">Unsubscribe</div>
          </div>
        </Bounce>
      </div>

      <div className="detail">
        <Fade bottom delay={300}>
          <span className="title2">Kho thẻ từ vựng từ nhiều nguồn khổng lồ</span>
        </Fade>
        {detail1.map((data, index) => (
          <div className="detail-row" key={`detail1_${index}`}>
            <Fade bottom delay={300}>
              <span className="detail-title">{data.title}</span>
            </Fade>
            <Bounce>
              <div className="check">
                <img alt="" src={Images.subscription.check} />
              </div>
            </Bounce>
            <Bounce>
              {data.isFree ? (
                <div className="check">
                  <img alt="" src={Images.subscription.check} />
                </div>
              ) : (
                <div className="notcheck">
                  <img alt="" src={Images.subscription.notCheck} />
                </div>
              )}
            </Bounce>
          </div>
        ))}
      </div>
      <div className="detail">
        <Fade bottom delay={300}>
          <span className="title2">Cộng đồng học cùng team chuyên môn và người bản xứ</span>
        </Fade>
        {detail2.map((data, index) => (
          <div className="detail-row" key={`detail2_${index}`}>
            <Fade bottom delay={300}>
              <span className="detail-title">{data.title}</span>
            </Fade>
            <Bounce>
              <div className="check">
                <img alt="" src={Images.subscription.check} />
              </div>
            </Bounce>
            <Bounce>
              {data.isFree ? (
                <div className="check">
                  <img alt="" src={Images.subscription.check} />
                </div>
              ) : (
                <div className="notcheck">
                  <img alt="" src={Images.subscription.notCheck} />
                </div>
              )}
            </Bounce>
          </div>
        ))}
      </div>
      <div className="detail">
        <Fade bottom delay={300}>
          <span className="title2">Bộ công cụ tự luyện mọi kỹ năng kèm AI</span>
        </Fade>
        <div className="detail-row">
          <Fade bottom delay={300}>
            <span className="detail-title">Lượt tìm kiếm</span>
          </Fade>
          <Bounce>
            <div className="detail-text">
              <span>Không giới hạn</span>
            </div>
          </Bounce>
          <Bounce>
            <div className="detail-text">
              <span>10 lượt/ ngày</span>
            </div>
          </Bounce>
        </div>
        {detail3.map((data, index) => (
          <div className="detail-row" key={`detail3_${index}`}>
            <Fade bottom delay={300}>
              <span className="detail-title">{data.title}</span>
            </Fade>
            <Bounce>
              <div className="check">
                <img alt="" src={Images.subscription.check} />
              </div>
            </Bounce>
            <Bounce>
              {data.isFree ? (
                <div className="check">
                  <img alt="" src={Images.subscription.check} />
                </div>
              ) : (
                <div className="notcheck">
                  <img alt="" src={Images.subscription.notCheck} />
                </div>
              )}
            </Bounce>
          </div>
        ))}
      </div>
    </div>
  );
}
